import React from "react";

const SlidingWorks = () => (
  <section className="sliding-works">
    <div className="text-container">
      <div className="text-wrapper">
        <p>Design</p>
        <p>Develop</p>
        <div>
          <p>Deploy</p>
          <p>UI/UX Design</p>
        </div>
        <p>Testing</p>
        <div>
          <p>Maintenance</p>
          <p>Integration</p>
          <p>Consulting</p>
        </div>
        <div>
          <p>Mobile Development</p>
        </div>
        <p>Web Development</p>

        <div>
          <p>Solutions</p>
          <p>E-commerce</p>
        </div>
        <p>Security</p>
        <p>Database Management</p>
      </div>
      <div className="text-wrapper">
        <p>Design</p>
        <p>Develop</p>
        <div>
          <p>Deploy</p>
          <p>UI/UX Design</p>
        </div>
        <p>Testing</p>
        <div>
          <p>Maintenance</p>
          <p>Integration</p>
          <p>Consulting</p>
        </div>
        <div>
          <p>Mobile Development</p>
        </div>
        <p>Web Development</p>

        <div>
          <p>Solutions</p>
          <p>E-commerce</p>
        </div>
        <p>Security</p>
        <p>Database Management</p>
      </div>
    </div>
  </section>
);

export default SlidingWorks;
