import React from "react";

import facebook from "../../assets/logo/facebook.svg";
import instagram from "../../assets/logo/instagram.svg";
import telegram from "../../assets/logo/telegram.svg";
import twitter from "../../assets/logo/twitter.svg";

import "../../styles/components/social-media.styles.scss";

export const SocialMediaComponent = ({ page }) => {
  const socialClassName = page === "Home" ? "social-media" : "about-social";
  const socialMediaLinks = [
    {
      id: 0,
      link: "https://www.facebook.com/nextdevt?mibextid=ZbWKwL",
      logo: facebook,
    },
    {
      id: 1,
      link: "https://instagram.com/nextdevt?igshid=NGExMmI2YTkyZg==",
      logo: instagram,
    },
    {
      id: 2,
      link: "https://t.me/nextdevt",
      logo: telegram,
    },
    {
      id: 3,
      link: "https://twitter.com/SamiMeskele?t=iv3okutD7imlyK6nvYuPIA&s=09",
      logo: twitter,
    },
  ];
  return (
    <section className={`${socialClassName}`}>
      {page === "Home" ? (
        <div className="social">
          <h2>SOCIAL</h2>
          <h1>MEDIA</h1>
          <p>
            Join our vibrant community of technology enthusiasts, clients, and
            partners.
          </p>
        </div>
      ) : (
        <div className="about-social-title">
          <h2>Follow Us</h2>
          <p>For more information</p>
        </div>
      )}

      <div className="social-media-links">
        {socialMediaLinks.map(({ id, link, logo }) => (
          <div key={id} className="social-media-single">
            <a href={link} target="_blank" rel="noreferrer">
              <img src={logo} alt="" />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};
