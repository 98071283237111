import "./App.css";
import { Route, Routes } from "react-router";
import { HomePage } from "./pages/home/index-page";
import { ServicesPage } from "./pages/services/services-page";
import { AboutPage } from "./pages/about/about-page";
import { JourneySwitch } from "./pages/journey/journey-switch";
import { CaseStudyPage } from "./pages/portifolio/case.study-page";
import JourneyPage from "./pages/journey/journey-page";
import { TermsPage } from "./pages/terms/terms-page";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" Component={HomePage} />
        <Route exact path="/services" Component={ServicesPage} />
        <Route exact path="/portifolio" Component={CaseStudyPage} />
        <Route exact path="/journey" Component={JourneySwitch} />
        <Route exact path="/journey/elements" Component={JourneyPage} />
        <Route exact path="/about" Component={AboutPage} />
        <Route exact path="/terms" Component={TermsPage} />
      </Routes>
    </div>
  );
}

export default App;
