import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/widgets/button.component";

import placeholder from "../../assets/portifolio/placeholder1.jpg";
import placeholder2 from "../../assets/portifolio/placeholder12.jpg";
import placeholder3 from "../../assets/portifolio/placeholder13.jpg";
import placeholder4 from "../../assets/portifolio/placeholder14.jpg";

const portfolioItems = [
  {
    id: 1,
    title: "Ada's Foods",
    type: "Mobile Application",
    image: placeholder,
  },
  {
    id: 2,
    title: "",
    type: "",
    image: placeholder2,
  },
  {
    id: 3,
    title: "FSD",
    type: "Website",
    image: placeholder3,
  },
  {
    id: 4,
    title: "Yene Buffet",
    type: "Mobile Application",
    image: placeholder4,
  },
  // Add more portfolio items here
];

export const PortfolioSection = () => {
  return (
    <section className="case-study">
      <div className="case-study-start">
        <div className="case-study-title">
          <h2 className="title-horizonatal">Portfolio</h2>
          <p>
            Discover a collection of our finest work, showcasing creativity,
            innovation, and expertise.
          </p>
        </div>
        {portfolioItems.map((item, index) => (
          <div
            className={`case-${item.id} case-layout`}
            style={{ backgroundImage: `url(${item.image})` }}
            key={index}
          >
            {index === 1 ? (
              <div></div>
            ) : (
              <div className="case-title">
                <div className="vertical-text-line" />
                <div>
                  <h1>{item.title}</h1>
                  <p>{item.type}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <Button whichButton={1}>
        <Link to={"/portifolio"}>More</Link>
      </Button>
    </section>
  );
};
