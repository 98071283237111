import React from "react";
import { NavComponent } from "../../components/widgets/nav.component";
import { comments, portfolio } from "../../data/case-study-data";
import { CaseLayout } from "../../components/case_study/case-layout.component";

import caseImage from "../../assets/case-study.svg";

import "../../styles/case-study.styles.scss";
import { FooterComponent } from "../../components/widgets/footer.component";
import { CommentComponent } from "../../components/case_study/case-comment.component";

export const CaseStudyPage = () => (
  <div className="case-study-page-layout">
    <NavComponent sentLink={"Case Study"} />
    <div className="case-header">
      <div className="case-header-text">
        <h3>Portifolio</h3>
        <h1>Case Study</h1>
        <p>
          Dive into our Next Dev case study, showcasing our client-driven
          approach. See how we turn client wishes into tailored software
          solutions, creating digital experiences that exceed expectations.
        </p>
      </div>
      <div className="case-header-image">
        <img src={caseImage} alt="" />
      </div>
    </div>
    <div className="case-body">
      <h1>
        Some of <span className="accent-text">Next Dev</span> Works
      </h1>
      <p>Elevating digital excellence with innovative solutions</p>
      {portfolio.map((value) => (
        <CaseLayout value={value} />
      ))}
    </div>
    <div className="case-saying">
      <div className="case-saying-inspired">
        <h4>
          Inspired by <span className="text-secondary">Albert Einstein's </span>
          quote, <br />
          "Imagination is more important than knowledge."
        </h4>
        <div className="horizontal-line-short" />
      </div>
      <div className="case-saying-quote">
        <h1>"</h1>
        <h2>
          In the realm of software,{" "}
          <span className="accent-text-">Next Dev</span> pioneers the art of
          turning{" "}
          <span className="text-italic text-light text-font">ideas</span> into{" "}
          <span className="accent-text">algorithms</span>, and{" "}
          <span className="text-italic text-light">dreams</span> into{" "}
          <span className="accent-text text-font text-bold">data</span>.
        </h2>
      </div>
    </div>
    <div className="comments">
      {comments.map(({ id, title, comment }) => (
        <CommentComponent id={id} title={title} comment={comment} />
      ))}
    </div>

    <FooterComponent />
  </div>
);
