import React, { useState } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";

//Components
import { NavComponent } from "../../components/widgets/nav.component";
import { FooterComponent } from "../../components/widgets/footer.component";
import { Button } from "../../components/widgets/button.component";
import { SocialMediaComponent } from "../../components/widgets/social-media.component";
import BounceEffect from "../../components/widgets/bounce.text.component";

//Assets
import thinkingImage from "../../assets/thinking-vector.svg";
import sucessImage from "../../assets/sucess.svg";
import creativeImage from "../../assets/idea.svg";
import locationImage from "../../assets/location-pin.svg";
import emailImage from "../../assets/mail.svg";
import phoneImage from "../../assets/phone.svg";
import chatImage from "../../assets/chat.svg";

//Datas
import { solutionData } from "../../data/select-solution-data";

//Utilities
import { clientsDb } from "../../firebase/firebase_config";

//Styles
import "../../styles/about.styles.scss";

export const AboutPage = () => {
  const [type, setType] = useState([]);
  const [clickedCategory, setClickedCategory] = useState(null);
  const [item, setItem] = useState(null);
  const [position, setPosition] = useState(-1);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function addData(email, phone) {
    try {
      if (type.length === 0) return;
      else if (position === -1) return;
      else if (!formData.email.includes("@")) return;
      else if (formData.phone.length !== 10) return;
      else {
        const docRef = await addDoc(collection(clientsDb, "clients"), {
          email: email,
          phone: phone,
          solution: clickedCategory,
          type: item,
          timestamp: new Date(),
        });
        console.log("Document written with ID: ", docRef.id);
        setFormData({ email: "", phone: "" });
        alert("Sucessfully Submited! We will contact you shortly");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <div className="about-page-layout">
      <NavComponent sentLink={"About"} />
      <div className="about-header">
        <h1 className="about-title">
          Let's Create{" "}
          <span className="accent-text">
            <BounceEffect data={["DREAMS", "HOPE", "VISION"]} />
          </span>{" "}
          Reality
        </h1>
        <p className="about-desc">
          Pioneering the future of innovation, where every challenge is an
          opportunity and every line of code is a step toward transformative
          excellence.
        </p>
        <div className="image-contanier">
          <div className="content-1">
            <div className="problem-text-1">
              <p>
                How can I fix my organizing problem, <br />
                is there easy solution for this
              </p>
            </div>
            <div className="problem-text-2">
              <p>What Can I DO?</p>
            </div>
          </div>
          <div className="content-2">
            <div className="problem-text-3">
              <p>Hmmm?</p>
            </div>
            <div className="image">
              <img src={thinkingImage} alt="Thinking" />
            </div>
          </div>
          <div className="content-3">
            <div className="problem-text-4">
              <p>
                This moving digital the best option at <br />
                this time
              </p>
            </div>
            <div className="problem-text-5">
              <p>How?</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="about-company-submit">
        <div className="about-company">
          <h4>WHO WE ARE</h4>
          <h1>
            Building <span className="accent-text">Tomorrow's</span> <br />{" "}
            Solutions Today
          </h1>
          <p>
            At Next Dev Solutions, we embody a forward-thinking ethos that
            resonates with our commitment to innovation and excellence. As a
            visionary force in the technology landscape, we take pride in
            crafting tomorrow's solutions today. Our identity is rooted in the
            dynamic fusion of creativity, cutting-edge technology, and a
            relentless pursuit of advancement.
          </p>
          <p>
            With a passion for progress and a vision for a brighter digital
            landscape, Next Dev Solutions is dedicated to pioneering the
            transformative technologies that shape the world of tomorrow.
          </p>
          <div className="about-company-unique">
            <h4>
              What makes as <span className="text-italic">UNIQUE </span>?
            </h4>
            <div className="unique-1">
              <div className="unique-image">
                <img src={sucessImage} alt="" />
              </div>
              <div className="unique-desc">
                <h2>How We Stand Out</h2>
                <p>Our unwavering commitment to client success.</p>
              </div>
            </div>
            <div className="unique-1">
              <div className="unique-image">
                <img src={creativeImage} alt="" />
              </div>
              <div className="unique-desc">
                <h2>What Sets Next Dev Apart</h2>
                <p>Our relentless pursuit of creative solutions</p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-info-submit">
          <div className="about-submit-work">
            <h4>What type of Solution you want?</h4>
            <p>
              Your Needs, Our Expertise: Tailored Solutions for Your Unique
              Challenges
            </p>
            <div className="solutions-menu">
              {Object.entries(solutionData).map(([category, option]) => (
                <p
                  key={category}
                  onClick={() => {
                    setType(option);
                    setClickedCategory(category);
                    setPosition(-1);
                  }}
                  className={`about-menu menu-${category} ${
                    clickedCategory === category ? "clicked-color" : ""
                  }`}
                >
                  {category}
                </p>
              ))}
            </div>

            {type.length === 0 ? (
              <div></div>
            ) : (
              <div className="types">
                <h4>Which Type?</h4>
                <p>Select the solution you want to have</p>
                <div className="solutions-menu-types">
                  {type.map((item, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => {
                          setItem(item);
                          setPosition(index);
                        }}
                        className={`about-menu menu-${item} ${
                          index === position ? "clicked-color" : ""
                        }`}
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            <h4>Client Information</h4>
            <div className="client-info">
              <div className="client-info-email">
                <h5>Email</h5>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="client-info-phone">
                <h5>Phone Number</h5>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <Button whichButton={1}>
            <Link onClick={() => addData(formData.email, formData.phone)}>
              Submit
            </Link>
          </Button>
        </div>
      </div>
      <div className="get-in-touch">
        <div className="get-in-touch-desc">
          <h2>Get in Touch</h2>
          <h1>
            Let's <span className="accent-text text-italic">chat</span> and make
            things happen.
          </h1>
          <p>
            We're eager to hear from you. Whether you have questions, need
            assistance, or want to explore potential collaborations, our team is
            here to help.
          </p>
          <p>
            Contact us now to start a conversation and discover how we can work
            together to bring your ideas to life.
          </p>
          <hr />

          <SocialMediaComponent page={"About"} />
        </div>
        <div className="where-to-find">
          <div className="company-profile head-office">
            <img src={locationImage} alt="" />
            <div>
              <h1>Head Office</h1>
              <p>In Front of Muluwengel Church, Tabor | Hawassa, Ethiopia</p>
            </div>
          </div>
          <div className="company-profile company-email">
            <img src={emailImage} alt="" />
            <div>
              <h1>Email</h1>
              <p>nextdevt@outlook.com</p>
              <p>nextdevt@gmail.com</p>
            </div>
          </div>
          <div className="company-profile company-phone">
            <img src={phoneImage} alt="" />
            <div>
              <h1>Phone Number</h1>
              <p>+251 711 3444 60</p>
              <p>+251 713 0845 50</p>
              <p>+251 945 1568 56</p>
            </div>
          </div>
          <div className="company-profile company-chat">
            <img src={chatImage} alt="" />
            <div>
              <h1>Telegram</h1>
              <p>@nextdev_sam</p>
              <p>@nextdevt</p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};
