import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavComponent } from "../../components/widgets/nav.component";
import nextLogo from "../../assets/next-logo.svg";
import arrowVector from "../../assets/arrow.svg";
import { servicesData } from "../../data/service-we-offer";

import "../../styles/services.styles.scss";

export const ServicesPage = () => {
  const [position, setPosition] = useState(0);
  const service = servicesData[position];

  const nextPosition = (currentPosition) =>
    currentPosition < 3 ? currentPosition + 1 : 0;

  return (
    <div className="services-page-layout">
      <NavComponent sentLink="Services" />
      <div className="services-header">
        <div className="services-body">
          <div className="services-body-title">
            <div className="vertical-text-line-long" />
            <div className="services-body-title-details">
              <h1>{service.title}</h1>
              <p>{service.desc}</p>
              <div className="services-body-title-details-logo">
                {service.worksLogo.map((image, index) => (
                  <img key={index} src={image} alt="work" />
                ))}
              </div>
              <div className="option-meet">
                <Link to="/about">Reach Out</Link>
              </div>
            </div>
          </div>
          <div className="services-body-image">
            <img src={service.image} alt="service" />
          </div>
        </div>
      </div>
      <div className="services-we-offer">
        <div className="we-offer-title">
          <h1>We Offer</h1>
          <p>{service.tag}</p>
        </div>
        <div className="we-offer">
          <div className="we-offer-now">
            <h4>Next</h4>
            <img src={arrowVector} alt="next" />
            <h1>0{position + 1}</h1>
            <h1>/</h1>
            <div
              onClick={() => setPosition(nextPosition)}
              className="next-logo-bgd"
            >
              <img src={nextLogo} alt="next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
