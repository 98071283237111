import React from "react";
import { NavComponent } from "../../components/widgets/nav.component";

export const TermsPage = () => (
  <div className="terms-container">
    <NavComponent />

    <div className="terms">
      <h1>Privacy</h1>
      <p>Last Updated: January 1, 2024</p>
      <p>
        Next Dev Software Solutions ("Next Dev," "we," "us," or "our") is
        committed to protecting your privacy. This Privacy Policy outlines how
        we collect, use, disclose, and safeguard your personal information when
        you use our software solutions or visit our website. By accessing or
        using our services, you consent to the practices described in this
        policy.
      </p>
      <h2>1. Information We Collect:</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          - Personal Information: This may include your name, email address,
          phone number, and other contact information.
        </li>
        <li>
          - Usage Data: We collect information about how you interact with our
          software, such as log files, device information, and usage patterns.
        </li>
      </ul>

      <h2>2. How We Use Your Information:</h2>

      <p>We use the collected information for the following purposes:</p>
      <ul>
        <li>- To provide and maintain our software solutions.</li>
        <li>- To improve, personalize, and enhance user experiences.</li>
        <li>
          - To communicate with you, respond to inquiries, and provide support.
        </li>
        <li>- To analyze usage patterns and improve our services.</li>
      </ul>
      <h2>3. Data Security:</h2>

      <p>
        We prioritize the security of your personal information and employ
        industry-standard measures to protect it from unauthorized access,
        disclosure, alteration, and destruction.
      </p>
      <h2>4. Information Sharing:</h2>

      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to third parties without your consent. However, we may share your
        information with trusted third-party service providers who assist us in
        operating our services, conducting our business, or servicing you.
      </p>
      <h2>5. Your Choices:</h2>

      <p>
        You have the right to review, update, or delete your personal
        information. You may opt-out of receiving promotional communications
        from us by following the unsubscribe link provided in such
        communications.
      </p>
      <h2>6. Updates to this Privacy Policy:</h2>

      <p>
        We reserve the right to update or modify this Privacy Policy at any
        time. We will notify you of any changes by posting the new policy on our
        website.
      </p>
      <h2>7. Contact Us:</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        practices, please contact us at [nextdevt@gmail.com].
      </p>
      <p>
        By using our software solutions, you acknowledge that you have read and
        understood this Privacy Policy and agree to its terms.
      </p>

      <h1>Terms</h1>
      <p>Last Updated: January 1, 2024</p>

      <p>
        By accessing or using Next Dev Software Solutions ("Next Dev," "we,"
        "us," or "our") services, you agree to comply with and be bound by the
        following terms and conditions. If you do not agree with these terms,
        please do not use our services.
      </p>
      <h2>1. Use of Services:</h2>

      <p>
        You agree to use our software solutions for lawful purposes and in a
        manner consistent with applicable laws and regulations.
      </p>

      <h2>2. Intellectual Property:</h2>

      <p>
        All intellectual property rights in our software solutions and related
        content are owned by Next Dev. You agree not to reproduce, distribute,
        or create derivative works without our express written permission.
      </p>
      <h2>3. User Accounts:</h2>

      <p>
        You may need to create an account to use certain features of our
        services. You are responsible for maintaining the confidentiality of
        your account information and agree to accept responsibility for all
        activities that occur under your account.
      </p>
      <h2>4. Privacy:</h2>

      <p>
        Our Privacy Policy outlines how we collect, use, disclose, and safeguard
        your personal information. By using our services, you consent to the
        practices described in our Privacy Policy.
      </p>
      <h2>5. Limitation of Liability:</h2>

      <p>
        Next Dev is not liable for any direct, indirect, incidental, special,
        consequential, or punitive damages arising out of or in connection with
        your use of our services.
      </p>
      <h2>6. Termination:</h2>

      <p>
        We reserve the right to terminate or suspend your access to our
        services, with or without notice, for any reason, including a breach of
        these terms.
      </p>
      <h2>7. Governing Law:</h2>

      <p>
        These terms are governed by and construed in accordance with the laws of
        [Jurisdiction], without regard to its conflict of law principles.
      </p>
      <h2>8. Changes to Terms:</h2>
      <p>
        We reserve the right to update or modify these terms at any time.
        Changes will be effective immediately upon posting on our website.
      </p>
      <h2>9. Contact Us:</h2>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at [nextdevt@gmail.com].
      </p>
      <p>
        By using our software solutions, you acknowledge that you have read and
        understood these terms and agree to be bound by them.
      </p>
    </div>
  </div>
);
