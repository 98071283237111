import React from "react";

export const Button = ({ whichButton, children }) => (
  <div
    className={`${
      whichButton === 0
        ? "reach-button"
        : whichButton === 1
        ? "more-button"
        : "custom-button"
    }`}
  >
    <p>{children}</p>
  </div>
);
