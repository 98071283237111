import gatherImage from "../assets/journey/gather.jpg";
import uiImage from "../assets/journey/ui.jpg";
import protoImage from "../assets/journey/prototype.jpg";
import developImage from "../assets/journey/develop.jpg";
import testImage from "../assets/journey/testing.jpg";
import deployImage from "../assets/journey/deploy.jpg";
import fixImage from "../assets/journey/fixing.jpg";

export const developmentAreas = [
  {
    area: "Gathering",
    subtitle: "The Nexus of Software Innovation",
    description:
      "At Next Dev, we foster a vibrant gathering of minds, igniting innovation at every turn. Join us to connect, share ideas, and collaborate, as we collectively push the boundaries of software development.",
    image: gatherImage,
    position: 0,
    points: [
      {
        title: "Understand",
        desc: "Having the full idea matters",
      },
      {
        title: "Roles",
        desc: "Asking the main characters",
      },
    ],
  },
  {
    area: "UI/UX Design",
    subtitle: "Designing Digital Delight",
    description:
      "At Next Dev, we craft exceptional user experiences through cutting-edge UI/UX design. Dive into our world of creativity, where design thinking meets technical brilliance to shape digital perfection.",
    image: uiImage,
    position: 1,
    points: [
      {
        title: "Visual Design",
        desc: "Color scheme, typography",
      },
      {
        title: "Interaction Design",
        desc: "Interaction with the interface elements.",
      },
    ],
  },
  {
    area: "Prototype",
    subtitle: "Prototyping Possibilities",
    description:
      "Next Dev empowers you to explore the art of prototyping, transforming ideas into tangible digital realities. Join our journey of experimentation and innovation, as we prototype the future.",
    image: protoImage,
    position: 2,
    points: [
      {
        title: "Prototyping Tools",
        desc: "Creating interactive prototypes.",
      },
      {
        title: "User Testing",
        desc: "Testing prototypes for feedback.",
      },
    ],
  },
  {
    area: "Development",
    subtitle: "Coding the Future Together",
    description:
      "At Next Dev, we breathe life into software through collaborative development. Dive into our coding ecosystem, where innovation thrives, and together, we write the code of tomorrow.",
    image: developImage,
    position: 3,
    points: [
      {
        title: "Frontend Dev",
        desc: "Implementing UI and interactivity.",
      },
      {
        title: "Backend Integration",
        desc: "Integrating server-side functionality.",
      },
    ],
  },
  {
    area: "Testing",
    subtitle: "Quality Beyond Measure",
    description:
      "Quality is our benchmark at Next Dev, where rigorous testing ensures software excellence. Join us in the pursuit of perfection, where every line of code is scrutinized to deliver the highest quality.",
    image: testImage,
    position: 4,
    points: [
      {
        title: "Usability Test",
        desc: "Evaluate user-friendliness.",
      },
      {
        title: "A/B Test",
        desc: "Design versions for optimization.",
      },
    ],
  },
  {
    area: "Deployment",
    subtitle: "Deploying Digital Advancements",
    description:
      "Next Dev takes software from conception to reality through seamless deployment. Explore our deployment expertise, where we bridge the gap between development and real-world usage.",
    image: deployImage,
    position: 5,
    points: [
      {
        title: "Release",
        desc: "Deploy for public use.",
      },
      {
        title: "Go Live",
        desc: "Accessible to users.",
      },
    ],
  },
  {
    area: "Maintenance",
    subtitle: "Sustaining Digital Brilliance",
    description:
      "Next Dev is committed to the continuous success of your software. Join our support and maintenance journey, where we stand by your side, ensuring your digital solutions shine brightly.",
    image: fixImage,
    position: 6,
    points: [
      {
        title: "Maintenance",
        desc: "Ongoing support and updates.",
      },
      {
        title: "Optimization",
        desc: "Enhance sustained performance.",
      },
    ],
  },
];
