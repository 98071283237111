// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBP3rWzhwPRPkBEDqUureRafTCn4Z4ALoQ",
  authDomain: "next-dev-8cb62.firebaseapp.com",
  projectId: "next-dev-8cb62",
  storageBucket: "next-dev-8cb62.appspot.com",
  messagingSenderId: "811636672132",
  appId: "1:811636672132:web:4eaaf5f6e483c00b1864d1",
  measurementId: "G-12GH5208ZC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const clientsDb = getFirestore(app);
