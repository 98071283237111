import facebookImage from "../assets/logo/facebook.svg";
import instagramImage from "../assets/logo/instagram.svg";
import telegramImage from "../assets/logo/telegram.svg";
import playstoreImage from "../assets/logo/playstore.svg";
import youtubeImage from "../assets/logo/youtube.svg";
import webImage from "../assets/logo/web.svg";
import adasfoodsImage from "../assets/portifolio/adasfoods.jpg";
import adasfoods1Image from "../assets/portifolio/adasfoods1.jpg";
import adasfoods2Image from "../assets/portifolio/adasfoods2.jpg";
import yenebuffetImage from "../assets/portifolio/yenebuffet.jpg";
import yenebuffet1Image from "../assets/portifolio/yenebuffet1.jpg";
import yenebuffet2Image from "../assets/portifolio/yenebuffet2.jpg";
import fsdImage from "../assets/portifolio/fsd3.jpg";
import fsd1Image from "../assets/portifolio/fsd2.jpg";
import fsd2Image from "../assets/portifolio/fsd.jpg";
import elifamImage from "../assets/portifolio/elifam1.jpg";
import elifam1Image from "../assets/portifolio/elifam2.jpg";
import elifam2Image from "../assets/portifolio/elifam3.jpg";
import elifam3Image from "../assets/portifolio/elifam4.jpg";
import lyricsImage from "../assets/portifolio/lyrics.jpg";

export const portfolio = [
  {
    id: 0,
    name: "Ada's Foods",
    typeofwork: "Mobile Application",
    desc: "A mobile application for Ada's Foods, offering convenient grocery delivery.",
    images: [adasfoods1Image, adasfoodsImage, adasfoods2Image],
    position: 0,
    social: [
      {
        socialImage: playstoreImage,
        socialLink:
          "https://play.google.com/store/apps/details?id=com.build.mammasfoods&pli=1",
      },
      {
        socialImage: facebookImage,
        socialLink: "https://www.facebook.com/profile.php?id=61551683009308",
      },
      {
        socialImage: instagramImage,
        socialLink: "https://www.instagram.com/adas_foods/",
      },
      {
        socialImage: telegramImage,
        socialLink: "https://t.me/mamasfoo",
      },
    ],
  },
  {
    id: 1,
    name: "Yene Buffet",
    typeofwork: "Mobile Application",
    desc: "A mobile application for Yene Buffet, providing information and online ordering.",
    images: [yenebuffetImage, yenebuffet1Image, yenebuffet2Image],
    position: 1,
    social: [
      {
        socialImage: playstoreImage,
        socialLink: "Coming Soon",
      },
      {
        socialImage: facebookImage,
        socialLink: "https://www.facebook.com/profile.php?id=100064362991215",
      },
      {
        socialImage: instagramImage,
        socialLink: "https://www.instagram.com/yenebuffet/",
      },
      {
        socialImage: telegramImage,
        socialLink: "https://t.me/+VaU3DDnkdQKAd4EL",
      },
    ],
  },
  {
    id: 2,
    name: "Elifam Soap",
    typeofwork: "Graphics",
    desc: "Elevate Elifam Soap promotion with striking Box Designs, T-shirts, and Banners.",
    images: [elifamImage, elifam1Image, elifam2Image, elifam3Image],
    position: 0,
    social: [
      {
        socialImage: facebookImage,
        socialLink: "https://www.facebook.com/",
      },

      {
        socialImage: instagramImage,
        socialLink: "https://www.instagram.com/everythingbutgas_et/",
      },
      {
        socialImage: telegramImage,
        socialLink: "Everything But Gas🛍",
      },
    ],
  },
  {
    id: 3,
    name: "FSD",
    typeofwork: "Website",
    desc: "A single page profile website for Non-organization company FSD",
    images: [fsdImage, fsd2Image, fsd1Image],
    position: 1,
    social: [
      {
        socialImage: webImage,
        socialLink: "https://www.fsdet.org.et",
      },
      {
        socialImage: facebookImage,
        socialLink: "https://www.facebook.com/",
      },
    ],
  },

  {
    id: 4,
    name: "Gosquala Sew",
    typeofwork: "Motion Graphics",
    desc: "Crafting compelling lyrics to enhance your song's appeal",
    images: [lyricsImage],
    position: 0,
    social: [
      {
        socialImage: youtubeImage,
        socialLink: "https://www.youtube.com/watch?v=-xB75bYf0rc",
      },
    ],
  },
];

export const comments = [
  {
    id: 1,
    title: "Elifam Soap Factory",
    comment:
      "We're extremely satisfied with Next Dev's software solutions. They've optimized our production processes and increased efficiency.",
  },
  {
    id: 2,
    title: "Elgo Consultancy",
    comment:
      "The website developed by Next Dev has enhanced our online presence, making it easier for clients to access our consulting services. We are very happy we have this kind of company here in Ethiopia.",
  },
  {
    id: 3,
    title: "FSD",
    comment:
      "Next Dev's website development has revolutionized the way we interact with our members. It's user-friendly and highly functional.",
  },
  {
    id: 4,
    title: "Yene Buffet",
    comment:
      "Thanks to Next Dev, our restaurant's online ordering system is smooth and efficient. Their mobile app has improved our customer experience.",
  },
  {
    id: 5,
    title: "Adas Foods",
    comment:
      "We've seen a significant increase in online sales after partnering with Next Dev for e-commerce solutions. Their work is exceptional.",
  },
  {
    id: 6,
    title: "FSD",
    comment:
      "Next Dev's web development services have resulted in more traffic and better user experiences on our website. We're impressed with the results.",
  },
  {
    id: 7,
    title: "Elifam Soap Factory",
    comment:
      "Next Dev's software solutions are a game-changer for our production efficiency. The customized software has exceeded our expectations.",
  },
  {
    id: 8,
    title: "Elgo Consultancy",
    comment:
      "Our clients are delighted with the user-friendly website Next Dev created for us. It's a testament to their exceptional design and development skills.",
  },
];
