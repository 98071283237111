import React, { useState, useEffect } from "react";
import { teamsData } from "../../data/home-teams";

import { CurrentStatus } from "../../components/widgets/current-status.component";

export const HomeTeamsComponent = () => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [teamsInfo, setTeamsInfo] = useState(teamsData[0]);
  const [transitionClass, setTransitionClass] = useState("");
  const [previousTeamsInfo, setPreviousTeamsInfo] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPosition(
        (currentPosition) => (currentPosition + 1) % teamsData.length
      );
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Add a transition class to trigger CSS transition
    setTransitionClass("transitioning");

    // Wait for the transition to finish and then update teamsInfo
    setTimeout(() => {
      setTeamsInfo(teamsData[currentPosition]);

      // Remove the transition class
      setTransitionClass("");

      // Update the previousTeamsInfo
      setPreviousTeamsInfo(teamsInfo);
    }, 300); // You might need to adjust this delay to match your CSS transition duration
  }, [currentPosition, teamsInfo]);

  return (
    <section className="teams">
      <div className="teams-expert">
        <div className="teams-title">
          <h2 className="title-horizonatal">Our Teams</h2>
          <p>
            Skilled professionals from various disciplines, collaborating to
            bring the project to life
          </p>
        </div>
        <div className="teams-info">
          <div className="teams-expert-desc">
            <div className="vertical-line" />
            <div className={`teams-expert-desc-layout ${transitionClass}`}>
              <h3>{teamsInfo.expert}</h3>
              <p className="team-expert-info">{teamsInfo.desc}</p>
              <p className="tags">{teamsInfo.tags}</p>
            </div>
          </div>
          <CurrentStatus position={currentPosition} size={teamsData.length} />
        </div>
      </div>
      <div className="teams-img">
        <div
          className={`single-team-img ${transitionClass}`}
          style={{ backgroundImage: `url(${teamsInfo.image})` }}
        />
        <div className="single-team-name">
          <h3>{teamsInfo.name}</h3>
          <p>{teamsInfo.position}</p>
        </div>
      </div>
    </section>
  );
};
