import React from "react";
import { Button } from "../widgets/button.component";
import samuriImage from "../../assets/samuri-white.svg";
import { Link } from "react-router-dom";

import "../../styles/components/journey-home.styles.scss";

export const JourneyHome = () => (
  <div className="journey-home-container">
    <div className="journey-home-container-text">
      <h1 className="title">
        <span className="text-large">Let's See </span> <br />
        How Things Happen at <br />
        <span className="accent-text text-large">Next Dev</span>
      </h1>
      <p className="desc">
        Witness the journey of problem-solving through innovative technologies,
        as we turn your obstacles into opportunities.
      </p>
      <div className="explore-journey">
        <Button whichButton={2}>
          <Link to="/journey/elements">Explore</Link>
        </Button>
      </div>
    </div>
    <div className="journey-home-container-image">
      <img src={samuriImage} alt="" />
      <div className="samuri-text">
        <p>
          As a coding <span className="accent-text">Samurai</span>, I invite you
          to embark on Next Dev's software journey.
        </p>
      </div>
    </div>
  </div>
);
