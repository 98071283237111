import React, { useState, useEffect } from "react";

import "../../styles/components/bounce-text.styles.scss";

const BounceEffect = ({ data }) => {
  const [text, setText] = useState(data[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bounce, setBounce] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      setBounce(true); // Trigger bounce animation
    }, 3000); // Delay before changing to the next phrase

    return () => clearTimeout(timer); // Cleanup function to clear the timeout
  }, [currentIndex, data]);

  useEffect(() => {
    // Remove the bounce animation after it plays once
    if (bounce) {
      const bounceTimer = setTimeout(() => {
        setText(data[currentIndex]); // Update the text when transitioning to the next phrase
        setBounce(false);
        setVisible(true); // Make the text visible after the bounce animation
      }, 500); // Adjust this duration to match your animation duration
      return () => clearTimeout(bounceTimer);
    }
  }, [data, currentIndex, bounce]);

  useEffect(() => {
    // Make the text invisible when the next text appears
    if (currentIndex !== 0) {
      const invisibleTimer = setTimeout(() => {
        setVisible(false);
      }, 3000); // Adjust this duration to match your text change delay
      return () => clearTimeout(invisibleTimer);
    }
  }, [currentIndex]);

  return (
    <h1 className="header-titles">
      <div
        className={`${bounce ? "bounce-text" : ""} ${
          visible ? "visible" : "invisible"
        }`}
      >
        {text}
      </div>
    </h1>
  );
};

export default BounceEffect;
