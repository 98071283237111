import React from "react";

export const HomeServiceList = ({ title, handleHoverEffect }) => (
  <div className="services-layout-offer">
    <ul>
      {title.map(({ name, description }) => (
        <li onMouseEnter={() => handleHoverEffect(name, description)}>
          {name}
        </li>
      ))}
    </ul>
  </div>
);
