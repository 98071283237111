import React from "react";
import { Link } from "react-router-dom";

//Custom Components
import { Button } from "../../components/widgets/button.component";
import BounceEffect from "../../components/widgets/bounce.text.component";
import { HomeTechSingleComponent } from "../../components/home_comp/home-tech.component";
import { NavComponent } from "../../components/widgets/nav.component";
import { FooterComponent } from "../../components/widgets/footer.component";
import { SocialMediaComponent } from "../../components/widgets/social-media.component";
import { HomeService } from "../../components/home_comp/home-service.component";
import { HomeTeamsComponent } from "../../components/home_comp/home-teams.component";
import { PortfolioSection } from "../../components/home_comp/home-case-study.component";
import SlidingWorks from "../../components/home_comp/home-sliding.component";
import Testimony from "../../components/widgets/testimony.component";

//Datas
import { phrases } from "../../data/phrases";
import { techStackData } from "../../data/tech-stack";
import { chooseUsInfo, workingCompanyLogo } from "../../data/home-teams";

//Styles
import "../../styles/home-page.styles.scss";

//Assets
import yeneBuffet from "../../assets/companies/yene_buffet.svg";
import whyUs from "../../assets/why-us.svg";
import temesgenTestimony from "../../assets/testimony/temesgen.jpg";
import elgoTestimony from "../../assets/testimony/elgo.jpg";
import journeyPerson from "../../assets/journey-person.svg";
import journeyWindow from "../../assets/journey-window.svg";

export const HomePage = () => {
  return (
    <div className="home-page-layout">
      <header>
        <NavComponent sentLink={"Home"} />
        <section className="header">
          <div className="header-text">
            <BounceEffect data={phrases} />
          </div>
          <p className="header-desc">
            Driving innovation with cutting-edge software solutions and a
            skilled, customer-focused team.
          </p>
          <Button whichButton={0}>
            <Link to={"/about"}>Reach Out</Link>
          </Button>
        </section>
      </header>

      <section className="about-us">
        <div className="about-us-title">
          <h2 className="title-vertical">About Us</h2>
          <div className="vertical-line" />
          <div />
        </div>
        <div className="about-us-desc">
          <h4>
            <span className="text-secondary">" Empowering Growth</span>
            <br />
            Through Innovative Software Solutions
          </h4>
          <p>
            Next Dev Solutions is a cutting-edge software development company at
            the forefront of technological innovation. With a passion for
            creating exceptional software solutions, Next Dev Solutions
            leverages the latest advancements in technology to deliver
            customized and scalable software products for businesses across
            various industries.
          </p>
          <p>
            The company prides itself on its team of highly skilled and
            experienced developers, engineers, and designers who are dedicated
            to transforming ideas into practical, efficient, and user-friendly
            software applications. They are adept at utilizing a wide range of
            programming languages, frameworks, and tools to develop robust and
            reliable software that meets the unique requirements of each client.
          </p>
          <Button whichButton={1}>
            <Link to={"/about"}>More</Link>
          </Button>
        </div>
      </section>

      <section className="working-with">
        <div className="working-with-bgd">
          <div className="working-with-bgd1" />
          <div className="working-with-bgd2" />
          <div className="working-with-bgd3">
            <div className="companies">
              <h2 className="title-horizonatal">
                Working alongside <span>companies</span>
              </h2>
              <p>
                Mostly trusted by this compaines and design and development is
                done by this tech.
              </p>
              <div className="companies-partner">
                <h4>PARTNER</h4>
                <img src={yeneBuffet} alt="" />
              </div>
            </div>
            <div className="horizontal-line-short" />
            <div className="horizontal-line" />
            <div className="companies-logo">
              {workingCompanyLogo.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="tech-stack">
        <div className="tech-stack-title">
          <h2 className="title-vertical">Tech Stack</h2>
          <div className="vertical-line" />
        </div>
        <div className="tech-stack-desc">
          {techStackData.map(({ id, techTitle, techDesc, imageSrc }) => (
            <HomeTechSingleComponent
              key={id}
              techTitle={techTitle}
              techDesc={techDesc}
              images={imageSrc}
            />
          ))}
        </div>
      </section>

      <HomeService />

      <PortfolioSection />

      <section className="journey">
        <div className="journey-title">
          <h2 className="title-vertical">Journey</h2>
          <div className="vertical-line" />
        </div>
        <div className="journey-desc">
          <div class="journey-container">
            <img className="journey-window" src={journeyWindow} alt="" />
            <img className="journey-person" src={journeyPerson} alt="" />
          </div>
        </div>
      </section>
      <Button whichButton={1}>
        <Link to={"/journey"}>Show Journey</Link>
      </Button>

      <HomeTeamsComponent />

      <section className="why-us">
        <h1 className="title-horizonatal">Why Us?</h1>
        <div className="why-us-reasons">
          <div className="why-img">
            <img src={whyUs} alt="" />
          </div>

          <div className="reasons">
            {chooseUsInfo.map(({ id, title, image }) => (
              <div key={id} className="reason">
                <img src={image} alt="" />
                <h3>{title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      <SlidingWorks />

      <section className="testimony">
        <div className="testimony-title">
          <h2 className="title-vertical">Testimony</h2>
          <div className="vertical-line" />
        </div>
        <div className="testimony-details">
          <div className="testimony-details-single">
            <Testimony
              text="As the CEO of Ada's Foods, I've been using the Ada's Food Mobile App by Next Dev for months, and it's been a game-changer. The app's user-friendly design and intuitive interface make ordering from our favorite restaurants incredibly convenient. Kudos to the Next Dev team for their outstanding work!"
              imageSrc={temesgenTestimony}
              name="Temesgen Elias"
              role="CEO of Ada's Foods"
            />

            <Testimony
              text="Next Dev has proven to be an invaluable partner for Elgo Consultancy, delivering a customized solution that perfectly aligns with our business requirements. Their attention to detail and commitment to excellence have significantly enhanced our operations. Grateful for the innovative solution they've provided!"
              imageSrc={elgoTestimony}
              name="Elguja Belayneh"
              role="Director of Elgo"
            />
          </div>
        </div>
      </section>

      <SocialMediaComponent page={"Home"} />
      <FooterComponent />
    </div>
  );
};
