export const phrases = [
  "Digital Transformation",
  "Software Solutions",
  "Business Growth",
  "Ideas into Reality",
  "Scalable Solutions",
  "Unlocking Potential",
  "Innovate. Succeed.",
  "Digital Experiences",
  "Accelerating Success",
];
