import team1 from "../assets/teams/person-1.png";
import team2 from "../assets/teams/person-2.jpg";
import team3 from "../assets/teams/person-3.jpg";
import team4 from "../assets/teams/person-4.jpg";

import working_1 from "../assets/companies/elifam.svg";
import working_2 from "../assets/companies/adasfoods.svg";
import working_3 from "../assets/companies/elgo.svg";
import working_4 from "../assets/companies/fsd.svg";
import working_5 from "../assets/companies/spark.svg";

import expert from "../assets/expert.svg";
import innovation from "../assets/innovate.svg";
import time from "../assets/time.svg";
import payment from "../assets/payment.svg";

export const workingCompanyLogo = [
  working_2,
  working_1,
  working_4,
  working_3,
  working_5,
];

// export const journeyInfo = [
//   {
//     id: 1,
//     title: "Gathering",
//     desc: "Identify and document the software requirements.",
//   },
//   {
//     id: 2,
//     title: "UI/UX Design",
//     desc: "Art of creating visually appealing user interfaces",
//   },
//   {
//     id: 3,
//     title: "Prototype",
//     desc: "Creating a preliminary version or mock-up of application.",
//   },
// ];

export const chooseUsInfo = [
  { id: 0, title: "Expertise", image: expert },
  { id: 1, title: "Innovation", image: innovation },
  { id: 2, title: "Quality & Timeliness", image: time },
  { id: 3, title: "Flexible Payment", image: payment },
];

export const teamsData = [
  {
    id: 12204,
    name: "Samson Meskele",
    position: "Founder of Next Dev",
    expert: "Front-End Expert",
    desc: "Experienced frontend developer with 5 years' expertise in building responsive and visually appealing web interfaces. Proficient in HTML, CSS, and JavaScript, delivering seamless user experiences.",
    tags: "#react #frontend #web",
    image: team1,
  },

  {
    id: 15624,
    name: "Biniyam Meskele",
    position: "Designer",
    expert: "UI/UX Specialist",
    desc: "Skilled UI/UX professional with a wealth of knowledge acquired through 3 years of dedicated work in crafting engaging and adaptable web interfaces, utilizing applications like Adobe XD, Figma, and Sketch. ",
    tags: "#uiux #xd #design",
    image: team2,
  },
  {
    id: 23965,
    name: "Ejigayehu Chorfo",
    position: "Manager",
    expert: "Director Expert",
    desc: "As the Director of Expertise at Next Dev, I bring over a decade of experience to lead our mission in turning innovative ideas into reality through cutting-edge software solutions. ",
    tags: "#managment #direct #trustworthy",
    image: team3,
  },
  {
    id: 63589,
    name: "Selam Tesfaye",
    position: "Finance",
    expert: "Professional Auditor",
    desc: "Crowned with over a decade of financial wisdom, I'm the Finance Expert at your service. I've navigated the intricate world of finance, ensuring that each decision is a step toward success.",
    tags: "#audit #cash #affordable",
    image: team4,
  },
];
