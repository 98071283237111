import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowRight from "../../assets/arrow-right.svg";

export const CaseLayout = ({ value }) => {
  const { id, name, typeofwork, desc, images, position, social } = value;
  const orderPosition = position === 0;
  const [imagePosition, setImagePosition] = useState(0);
  return (
    <div className="case-layout-container">
      <div className="case-main-container">
        <div className="main-image" style={{ order: orderPosition ? 1 : 2 }}>
          <img src={images[imagePosition]} alt="Case Profile" />
          <div
            onClick={() => {
              setImagePosition(
                imagePosition < images.length - 1 ? imagePosition + 1 : 0
              );
            }}
          >
            <p>Next</p>
            <img src={arrowRight} alt="Arrow Right" />
          </div>
        </div>
        <div
          className="main-text-content"
          style={{ order: orderPosition ? 2 : 1 }}
        >
          <h1>{name}</h1>
          <h3>{typeofwork}</h3>
          <p>{desc}</p>

          <div className="case-social-container">
            <div className="social-img">
              {social.map(({ socialImage, socialLink }) => (
                <Link key={id} to={socialLink}>
                  <img src={socialImage} alt="social" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
