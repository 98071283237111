import React from "react";

const Testimony = ({ text, imageSrc, name, role }) => {
  return (
    <div className="testimony-single">
      <p className="testimony-test">{text}</p>
      <div className="testimony-single-person">
        <div className="testimony-img">
          <img src={imageSrc} alt="User" />
        </div>
        <div className="testimony-person">
          <h4>{name}</h4>
          <p>{role}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
