import React from "react";

export const CurrentStatus = ({ position, size }) => {
  const loopArray = Array.from({ length: size });
  return (
    <div className="current-status-layout current-journey">
      {loopArray.map((_, index) => (
        <div
          className={`points ${index === position ? "current-switch" : ""}`}
          key={index}
        />
      ))}
    </div>
  );
};
