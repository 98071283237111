import React, { useState, useEffect } from "react";
import { developmentAreas } from "../../data/journey-elements-data";
import { JourneyMenu } from "../../components/widgets/journey-menu.component";
import { JourneyElement } from "../../components/journey_elements/journey-element.component";

import "../../styles/journey.styles.scss";

const JourneyPage = () => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 50) {
        if (position > 5) return;
        setPosition(position + 1);
      } else if (event.deltaY < 50) {
        if (position < 1) return;
        setPosition(position - 1);
      }
    };

    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [position]);

  return (
    <div className="journey-page-layout">
      <JourneyMenu />
      <div className="switch-component-container">
        {developmentAreas.map(({ position: itemPosition, ...otherProps }) =>
          itemPosition === position ? (
            <JourneyElement
              key={itemPosition}
              position={itemPosition}
              data={otherProps}
              size={developmentAreas.length}
            />
          ) : null
        )}
      </div>
    </div>
  );
};

export default JourneyPage;
