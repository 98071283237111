import React from "react";
import { ReactComponent as Logo } from "../../assets/nextdev-logo.svg";
import "../../styles/nav.styles.scss";
import "../../styles/journey.styles.scss";

export const JourneyMenu = () => (
  <nav className="navbar">
    <div className="app-logo">
      <Logo className="app-logo-icon" alt="NextDev Logo" />
      <h1 className="app-name">
        Next <span>Dev</span>
      </h1>
    </div>
  </nav>
);
