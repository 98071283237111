import React from "react";

export const HomeTechSingleComponent = ({ techTitle, techDesc, images }) => (
	<div className="home-tech-layout">
		<h2>{techTitle}</h2>
		<p>{techDesc}</p>
		<div className="designs-tech-logo">
			{images.map((image) => (
				<img src={image} alt="Hello" />
			))}
		</div>
	</div>
);
