import React, { useState } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";

//Assets
import emailSVG from "../../assets/mail.svg";
import phoneSVG from "../../assets/phone.svg";

//Components
import { Button } from "../../components/widgets/button.component";
import { ReactComponent as Logo } from "../../assets/nextdev-logo.svg";

//Data
import { pageLinks } from "../../data/page-links";

//Utilities
import { clientsDb } from "../../firebase/firebase_config";

//Styles
import "../../styles/components/footer.styles.scss";

export const FooterComponent = () => {
  const [email, setEmail] = useState("");

  const sendEmail = async () => {
    if (email.includes("@"))
      try {
        await addDoc(collection(clientsDb, "emails"), {
          email: email,
          timestamp: new Date(),
        });
        alert("Sucessfully Submited! We will contact you shortly");
        setEmail("");
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("Somthing went Wrong! We are very sorry");
      }
    else {
      alert("Check Your Email Address");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <footer className="footer">
      <div className="have-project">
        <h1>
          Ready To <span>LAUNCH?</span>
        </h1>
        <Button whichButton={0}>
          <Link to={"/about"}>
            <p>Reach Out!</p>
            <p>Let's Create your dream</p>
          </Link>
        </Button>
      </div>
      <div className="footer-info">
        <div className="footer-details">
          <div className="footer-company-logo">
            <Logo className="app-logo-icon" alt="NextDev Logo" />
            <div>
              <h1 className="app-name">
                Next <span>Dev</span>
              </h1>
              <p>Empowering Your Digital Evolution</p>
            </div>
          </div>
          <div className="footer-menu-details">
            <div className="email">
              <img src={emailSVG} alt="" />
              <p>nextdevt@gmail.com</p>
            </div>
            <div className="phone">
              <img src={phoneSVG} alt="" />
              <div>
                <p>+251 711 34 4460</p>
                <p>+251 945 15 6856</p>
                <p>+251 713 08 4550</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-menu">
          <h1>Explore</h1>

          <ul>
            {pageLinks.map(({ name, link, index }) => (
              <Link to={link} key={index}>
                {name}
              </Link>
            ))}
          </ul>
        </div>
        <div className="footer-join">
          <h3>Join Us</h3>
          <form>
            <label name="email">Email</label>
            <input
              name="username"
              type="email"
              value={email}
              required
              onChange={handleEmailChange}
            />
            <Button whichButton={0}>
              <Link onClick={() => sendEmail()}>Send</Link>
            </Button>
          </form>
        </div>
      </div>
      <div className="footer-copy">
        <div className="copyright">
          <div>
            <p>Copyright 2023-24 | Next Dev Company All rights reserved.</p>
            <h4>Next Dev</h4>
          </div>
          <div className="privacy">
            <Link to="/terms">Privacy</Link>
            <p>|</p>
            <Link to="/terms">Terms</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
