import React from "react";
import { JourneyHome } from "../../components/journey_elements/journey-home.component";
import { JourneyMenu } from "../../components/widgets/journey-menu.component";

import "../../styles/journey.styles.scss";

export const JourneySwitch = () => (
  <div className="journey-page-layout">
    <JourneyMenu />
    <JourneyHome />
  </div>
);
