import React from "react";
import { ReactComponent as Logo } from "../../assets/nextdev-logo.svg";
import { Link } from "react-router-dom";
import { pageLinks } from "../../data/page-links";
import "../../styles/nav.styles.scss";

export const NavComponent = ({ sentLink }) => (
  <nav className="navbar">
    <div className="app-logo">
      <Logo className="app-logo-icon" alt="NextDev Logo" />
      <h1 className="app-name">
        Next <span>Dev</span>
      </h1>
    </div>
    <div className="links-layout">
      {pageLinks.map(({ name, link, index }) => (
        <Link
          key={index}
          to={link}
          className={`${name === sentLink ? "current" : ""} links`}
        >
          {name}
        </Link>
      ))}
    </div>
  </nav>
);
