import photoshop from "../assets/logo/photoshop.svg";
import indesign from "../assets/logo/indesign.svg";
import ai from "../assets/logo/illustrator.svg";
import xd from "../assets/logo/xd.svg";
import figma from "../assets/logo/figma.svg";
import sketch from "../assets/logo/sketch.svg";
import html from "../assets/logo/html5.svg";
import css from "../assets/logo/css3.svg";
import js from "../assets/logo/js.svg";
import angular from "../assets/logo/angular.svg";
import react from "../assets/logo/react.svg";
import python from "../assets/logo/python.svg";
import node from "../assets/logo/node.svg";
import php from "../assets/logo/php.svg";
import java from "../assets/logo/java.svg";
import kotlin from "../assets/logo/kotlin.svg";
import flutter from "../assets/logo/flutter.svg";
import swift from "../assets/logo/swift.svg";

export const techStackData = [
  {
    id: 1,
    techTitle: "Designs",
    techDesc: "Transforming Vision into Visual Masterpieces",
    imageSrc: [photoshop, indesign, ai],
  },
  {
    id: 2,
    techTitle: "UI/UX",
    techDesc: "Elevating User Experiences through Intuitive Design",
    imageSrc: [xd, figma, sketch],
  },
  {
    id: 3,
    techTitle: "Front-End",
    techDesc: "Building Engaging Interfaces with Cutting-Edge Code",
    imageSrc: [html, css, js, angular, react],
  },
  {
    id: 4,
    techTitle: "Back-End",
    techDesc: "Powering Seamless Functionality with Robust Backend Solutions",
    imageSrc: [python, node, php],
  },
  {
    id: 5,
    techTitle: "Mobile",
    techDesc: "Innovating the Mobile Experience, One App at a Time",
    imageSrc: [kotlin, java, flutter, swift],
  },
];

export const nextDevServices = {
  Designs: [
    {
      name: "Social Media Ads",
      description:
        "Creating intuitive and visually appealing user interfaces for web and mobile applications.",
    },
    {
      name: "Banner",
      description:
        "Designing eye-catching graphics and illustrations for various digital and print materials.",
    },
    {
      name: "Logo Design",
      description:
        "Crafting unique and memorable logos that represent your brand identity.",
    },
    {
      name: "Poster",
      description:
        "Designing print materials such as brochures, flyers, posters, and more.",
    },
    {
      name: "Brochures",
      description: "Materials such as brochures, flyers, posters, and more.",
    },
  ],
  Web: [
    {
      name: "Front-End",
      description:
        "Building dynamic and responsive websites using the latest web technologies.",
    },
    {
      name: "Back-End",
      description:
        "Creating online stores and e-commerce platforms for selling products and services.",
    },
    {
      name: "Database",
      description:
        "Managing and optimizing databases to ensure data integrity and accessibility.",
    },
    {
      name: "Full-stack",
      description:
        "Managing and optimizing databases to ensure data integrity and accessibility.",
    },
  ],
  Mobile: [
    {
      name: "Android",
      description:
        "Building Android apps that cater to the diverse Android device ecosystem.",
    },
    {
      name: "iOS",
      description:
        "Creating native iOS apps that provide a seamless user experience on Apple devices.",
    },
    {
      name: "Both",
      description:
        "Developing mobile applications for iOS and Android platforms, tailored to your needs.",
    },
  ],
};
