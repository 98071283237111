import React from "react";
import { CurrentStatus } from "../widgets/current-status.component";
import samuriImage from "../../assets/samuri-white.svg";
import arrowDownImage from "../../assets/arrow-down.svg";

import "../../styles/components/journey-element.styles.scss";

export const JourneyElement = ({ position, data, size }) => {
  const { area, subtitle, description, image, points } = data;
  return (
    <div className="journey-element-container">
      <div className="journey-element-container-image">
        <div className="journey-element-image">
          <img src={image} alt="" />
        </div>
      </div>
      <div className="journey-element-container-desc">
        <div className="journey-element-desc">
          <h1>{area}</h1>
          <h2>{subtitle}</h2>
          <p>{description}</p>
          <div className="element-details">
            {points.map(({ title, desc }) => (
              <div key={position} className="element-details-1">
                <h4>{title}</h4>
                <p>{desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <img src={samuriImage} alt="" />
      <div className="scroll-indicator">
        <img src={arrowDownImage} alt="" />
        <p>Scroll</p>
      </div>
      <CurrentStatus position={position} size={size} />
    </div>
  );
};
