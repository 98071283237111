import mobileDev from "../assets/services/mobile-dev.png";
import webDev from "../assets/services/web-dev.png";
import graphics from "../assets/services/graphics.png";
import ui from "../assets/services/ui.png";
import androidPng from "../assets/logo/android.png";
import iOSPng from "../assets/logo/iOS.png";
import reactLogo from "../assets/logo/react.svg";
import nodeLogo from "../assets/logo/node.svg";
import xdLogo from "../assets/logo/xd.svg";
import figmaLogo from "../assets/logo/figma.svg";
import psLogo from "../assets/logo/photoshop.svg";
import aiLogo from "../assets/logo/illustrator.svg";
import idLogo from "../assets/logo/indesign.svg";

export const servicesData = [
  {
    title: "App Development",
    desc: "Create amazing mobile apps with Next Dev. Transform your ideas into user-friendly Android and iOS apps.",
    tag: "Mobile App Development",
    image: mobileDev,
    worksLogo: [androidPng, iOSPng],
  },
  {
    title: "Web Development",
    desc: "Build stunning web applications with Next Dev. Turn your concepts into exceptional web experiences.",
    tag: "Web Application Development",
    image: webDev,
    worksLogo: [reactLogo, nodeLogo],
  },
  {
    title: "UI/UX Design",
    desc: "Craft beautiful prototypes with Next Dev. Shape your ideas into seamless user experiences.",
    tag: "Prototype Design",
    image: ui,
    worksLogo: [xdLogo, figmaLogo],
  },
  {
    title: "Graphics",
    desc: "Design captivating graphics with Next Dev. Transform your vision into stunning and awesome visuals.",
    tag: "Graphics Design",
    image: graphics,
    worksLogo: [idLogo, psLogo, aiLogo],
  },
];
