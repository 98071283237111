import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/widgets/button.component";
import { HomeServiceList } from "./home-service-list.component";

import { nextDevServices } from "../../data/tech-stack";

export const HomeService = () => {
  const [hoveredItem, setHoveredItem] = useState({
    title: "Social Media Ads",
    description:
      "Creating intuitive and visually appealing user interfaces for web and mobile applications.",
  });

  const [clickedMenu, setClickedMenu] = useState(1);
  const [clickedColorChange, setClickedColorChange] = useState("clicked");

  const handleDesignsClick = () => {
    setClickedMenu(1);
    setClickedColorChange("clicked");
  };

  const handleWebClick = () => {
    setClickedMenu(2);
    setClickedColorChange("clicked");
  };

  const handleMobileClick = () => {
    setClickedMenu(3);
    setClickedColorChange("clicked");
  };

  const handleHoverEffect = (title, description) => {
    setHoveredItem({ title, description });
  };

  return (
    <section className="services">
      <div className="services-layout">
        <div className="services-layout-desc">
          <h6 className="number">0{clickedMenu}</h6>
          <h1 className="services-title">{hoveredItem.title}</h1>
          <p>{hoveredItem.description}</p>
          <Button whichButton={2}>
            <Link to={"/services"}>More</Link>
          </Button>
        </div>
        <div className="services-layout-menu">
          <div className="graphics-design">
            <h5>01</h5>
            <div className="horizontal-line-shorter" />
            <p
              onClick={handleDesignsClick}
              className={clickedMenu === 1 ? clickedColorChange : ""}
            >
              Designs
            </p>
          </div>
          <div className="web-design">
            <h5>02</h5>
            <div className="horizontal-line-shorter" />
            <p
              onClick={handleWebClick}
              className={clickedMenu === 2 ? clickedColorChange : ""}
            >
              Web
            </p>
          </div>
          <div className="mobile-design">
            <h5>03</h5>
            <div className="horizontal-line-shorter" />
            <p
              onClick={handleMobileClick}
              className={clickedMenu === 3 ? clickedColorChange : ""}
            >
              Mobile
            </p>
          </div>
        </div>
        {
          <HomeServiceList
            title={
              clickedMenu === 1
                ? nextDevServices.Designs
                : clickedMenu === 2
                ? nextDevServices.Web
                : nextDevServices.Mobile
            }
            handleHoverEffect={handleHoverEffect}
          />
        }
      </div>
      <h1 className="selected-menu">
        {clickedMenu === 1 ? "DESIGNS" : clickedMenu === 2 ? "WEB" : "MOBILE"}
      </h1>
    </section>
  );
};
